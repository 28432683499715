import useApiClient, { useRequestInit } from './apiClient';
import { ServiceBinding } from '@core/lib/serviceBindings';
import type { UserProfile } from '@user-service/features/userProfile/model/userProfile';
import type { Organization } from '@user-service/features/organization/model';
import type { UserSession } from '@user-service/features/userSession/model';

export const useUserApi = (
  baseUrl: string,
  serviceBinding: ServiceBinding | null = null,
  sessionCookie: string | null = null
) => {
  const apiClient = useApiClient(serviceBinding?.fetch.bind(serviceBinding));
  const getRequestInit = useRequestInit(serviceBinding?.fetch, sessionCookie);

  const deleteAvatar = async () =>
    await apiClient(
      `${baseUrl}/api/v3/user/profile/avatar`,
      getRequestInit({
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
      false
    );

  const uploadAvatar = async (image: File) =>
    await apiClient<string>(
      `${baseUrl}/api/v3/user/profile/avatar`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/text',
          'Content-Type': image.type,
        },
        body: image,
      }),
      true,
      true
    );

  const updateUserProfil = async (profile: Partial<UserProfile>) =>
    await apiClient(
      `${baseUrl}/api/v3/user/profile`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile),
      }),
      false
    );

  const changePassword = async (token: string, newPassword: string) =>
    await apiClient(
      `${baseUrl}/api/v3/user/changePassword`,
      getRequestInit({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          newPassword,
        }),
      }),
      false
    );

  const setPassword = async (oldPassword: string, newPassword: string) =>
    await apiClient(
      `${baseUrl}/api/v3/user/password?${new URLSearchParams({ oldPassword, newPassword }).toString()}`,
      getRequestInit({
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
      }),
      false
    );

  const verifyChangePasswordToken = async (token: string) =>
    await apiClient(`${baseUrl}/api/v3/user/verifyChangePasswordToken/${token}`, {}, false);

  const createResetPasswordToken = async (email: string, resetPasswordLink: string) =>
    await apiClient(
      `${baseUrl}/api/v3/user/createRestorePasswordToken`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify({
          userId: email,
          baseUrl: resetPasswordLink,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      false
    );

  const getUserProfile = async () =>
    await apiClient<UserProfile>(
      `${baseUrl}/api/v3/user/profile`,
      getRequestInit({
        method: 'GET',
      })
    );

  const getOrganizationById = async (id: number) =>
    await apiClient<Organization>(
      `${baseUrl}/api/v3/user/organization/${id}`,
      getRequestInit({
        method: 'GET',
      })
    );

  const getUserSession = async () =>
    await apiClient<UserSession>(
      `${baseUrl}/api/v3/user/session`,
      getRequestInit({
        method: 'GET',
      })
    );

  const updateCurrentOrganization = async (id: number) =>
    await apiClient<UserSession>(
      `${baseUrl}/api/v3/user/session/current_organization?id=${id}`,
      getRequestInit({
        method: 'PUT',
      })
    );

  const updateLanguage = async (language: string) => 
    await apiClient(
      `${baseUrl}/api/v3/user/profile/language?language=${language}`,
      getRequestInit({
        method: 'PUT',
      }),
      false
    );

  return {
    changePassword,
    setPassword,
    verifyChangePasswordToken,
    createResetPasswordToken,
    getUserProfile,
    getOrganizationById,
    updateUserProfil,
    updateLanguage,
    uploadAvatar,
    deleteAvatar,
    getUserSession,
    updateCurrentOrganization,
    queryKeys: {
      userProfile: 'USER_PROFILE'
    }
  };
};
